import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';

import { initializeRouter, registerApplicationRoutes } from 'controller/router';
import { resolvables } from 'controller/resolvables';
import { initializeStore, persistStore } from 'controller/store';
import { migrateStyles } from 'controller/tpa-styles-migration';
import { bindViewModel, initializeViewModel } from 'controller/view-model';
import { bindIntegrations } from 'controller/integrations';

import { resolve } from './resolve';

export const controller: CreateControllerFn = function (params) {
  const { flowAPI } = params;
  const { wixCodeApi } = params.controllerConfig;

  const router = initializeRouter(params);

  flowAPI.bi?.updateDefaults({ userEntry: 'site' });

  return {
    async pageReady() {
      const store = initializeStore(params);
      const vm = initializeViewModel(router, store, params);

      registerApplicationRoutes(router, vm, store, params);

      migrateStyles(params);
      bindViewModel(vm, store, params);
      bindRouter(router, params.flowAPI);

      await resolvables(params, resolve(vm, params));
      await bindIntegrations(vm);

      persistStore(store, params);

      wixCodeApi.user.onLogin(() => resolve(vm, params));
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
  };
};
